import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useWindowWidth } from '@react-hook/window-size';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const IndividualNewsletter = () => {
  const { newsletterName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(null);
  const windowWidth = useWindowWidth();
  const pdfWidth = Math.min(windowWidth * 0.9, 800);
  const [isCurrentPageLoading, setIsCurrentPageLoading] = useState(true);
  const [loadedPages, setLoadedPages] = useState(new Set());
  const apiUrl = process.env.REACT_APP_API_URL;

  const goToPrevPage = () => setPageNumber((prev) => Math.max(prev - 1, 1));
  const goToNextPage = () => setPageNumber((prev) => Math.min(prev + 1, numPages));

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        goToPrevPage();
      } else if (event.key === 'ArrowRight') {
        goToNextPage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [pageNumber, numPages]);

  useEffect(() => {
    if (location.state?.pdfId) {
      setPdfUrl(`${apiUrl}/api/image/${location.state.pdfId}`);
    }
  }, [location.state]);

  const preloadPage = (pageNum) => {
    if (pageNum <= numPages && pageNum > 0 && !loadedPages.has(pageNum)) {
      setLoadedPages(prev => new Set([...prev, pageNum]));
    }
  };

  useEffect(() => {
    if (numPages) {
      preloadPage(pageNumber + 1);
      preloadPage(pageNumber - 1);
    }
  }, [pageNumber, numPages]);

  const LoadingSpinner = () => (
    <div className="flex flex-col items-center justify-center h-[60vh]">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      <p className="mt-4 text-xl text-gray-600">Loading page...</p>
    </div>
  );

  if (!pdfUrl) {
    return (
      <div>
        <Navbar
          colorSFR="main_text"
          colorRest="text-[#9999A3]"
          colorBox="bg-black"
          colortextBox="text-white"
          colorhover="hover:bg-[#464646]"
        />
        <div className="container mx-auto px-4 py-24">
          <button onClick={() => navigate(-1)} className="btn-main mb-4 py-2 px-4 rounded text-white">
            « Back to Newsletters
          </button>
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">
            Newsletter not found
          </h1>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="hover:bg-[#464646]"
      />
      <div className="container mx-auto px-4 py-24">
        <button onClick={() => navigate(-1)} className="btn-main mb-4 py-2 px-4 rounded text-white">
          « Back to Newsletters
        </button>
        <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">
          {newsletterName}
        </h1>
        <div className="flex flex-col items-center">
          <div className="mb-4 flex items-center">
            <button
              onClick={goToPrevPage}
              disabled={pageNumber <= 1 || isCurrentPageLoading}
              className="btn_lm py-1 px-3 rounded mr-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              ‹ Prev
            </button>
            <span className="main_text">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= numPages || isCurrentPageLoading}
              className="btn_lm py-1 px-3 rounded ml-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next ›
            </button>
          </div>
          <div className="pdf-container relative">
            <Document
              file={pdfUrl}
              onLoadSuccess={({ numPages }) => {
                setNumPages(numPages);
                setPageNumber(1);
                setLoadedPages(new Set([1]));
              }}
              loading={<LoadingSpinner />}
              onLoadError={(error) => console.error('Error while loading PDF:', error)}
            >
              <Page
                pageNumber={pageNumber}
                width={pdfWidth}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                loading={<LoadingSpinner />}
                onLoadSuccess={() => setIsCurrentPageLoading(false)}
                onRenderSuccess={() => setIsCurrentPageLoading(false)}
                onLoadStart={() => setIsCurrentPageLoading(true)}
              />
              
              {pageNumber < numPages && (
                <div className="hidden">
                  <Page
                    pageNumber={pageNumber + 1}
                    width={pdfWidth}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                </div>
              )}
              
              {pageNumber > 1 && (
                <div className="hidden">
                  <Page
                    pageNumber={pageNumber - 1}
                    width={pdfWidth}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                </div>
              )}
            </Document>
          </div>
          <div className="mt-4 flex items-center">
            <button
              onClick={goToPrevPage}
              disabled={pageNumber <= 1 || isCurrentPageLoading}
              className="btn_lm py-1 px-3 rounded mr-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              ‹ Prev
            </button>
            <span className="main_text">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={pageNumber >= numPages || isCurrentPageLoading}
              className="btn_lm py-1 px-3 rounded ml-4 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next ›
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndividualNewsletter;