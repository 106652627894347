import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const EventGallery = () => {
  const [folders, setFolders] = useState([]);
  const [folderImages, setFolderImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const cache = new Map();

  const fetchFolderImages = async (folderId) => {
    if (cache.has(folderId)) {
      setFolderImages(prev => ({
        ...prev,
        [folderId]: cache.get(folderId)
      }));
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl}/api/drive-photos?folderID=${folderId}&size=thumbnail`
      );
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      cache.set(folderId, data);
      setFolderImages(prev => ({
        ...prev,
        [folderId]: data
      }));
    } catch (error) {
      console.error("Error fetching folder images:", error);
      setError("Failed to load images");
    }
  };

  const fetchFolders = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/drive-folders`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setFolders(data);
      await Promise.all(data.map(folder => fetchFolderImages(folder.id)));
    } catch (error) {
      console.error("Error fetching folders:", error);
      setError("Failed to load folders");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />
      <div className="container mx-auto px-4 py-24">
        <h1 className="text-5xl font-bold text-center mb-12 main_text">
          Event Gallery
        </h1>
        {loading ? (
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        ) : error ? (
          <div className="text-center">
            <div className="text-red-600 text-xl">{error}</div>
          </div>
        ) : folders.length === 0 ? (
          <div className="text-center text-gray-500 mt-8">
            No event folders found
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {folders.map((folder) => (
              <div
                key={folder.id}
                className="aspect-square rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
              >
                <Link
                  to={`/gallery/events/${encodeURIComponent(folder.name)}`}
                  state={{ folderId: folder.id }}
                  className="block h-full"
                >
                  <div className="aspect-w-16 aspect-h-9">
                    {folderImages[folder.id]?.length > 0 ? (
                      <LazyLoadImage
                        alt={folder.name}
                        effect="blur"
                        src={`${apiUrl}${folderImages[folder.id][0].thumbnailUrl}`}
                        className="w-full h-full object-cover"
                        placeholderSrc="/placeholder-image.jpg"
                        width={400}
                        height={256}
                        loading="lazy"
                      />
                    ) : (
                      <div className="w-full h-64 bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-400">No images available</span>
                      </div>
                    )}
                  </div>
                  <div className="p-4">
                    <h2 className="text-xl font-semibold main_text hover:text-blue-600 transition-colors duration-200">
                      {folder.name}
                    </h2>
                    <p className="text-gray-500 text-sm mt-2">
                      {folderImages[folder.id]?.length || 0} images
                    </p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default EventGallery;