import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const GlassButton = ({ children, className = "", onClick = () => {} }) => (
  <button
    onClick={onClick}
    className={`
      backdrop-blur-md 
      bg-opacity-10 
      border border-current
      main_text
      hover:bg-opacity-20 
      transition-all 
      duration-300 
      rounded-full 
      font-bold 
      text-base
      md:text-lg 
      py-3
      md:py-4 
      px-6
      md:px-8
      w-full
      sm:w-auto
      transform 
      hover:-translate-y-1 
      hover:shadow-lg
      ${className}
    `}
  >
    {children}
  </button>
);

const StatCard = ({ title, description }) => (
  <div className="p-4 md:p-6 rounded-xl bg-gray-50 hover:shadow-md transition-shadow duration-300">
    <h3 className="text-2xl md:text-3xl font-bold main_text mb-2">{title}</h3>
    <p className="text-gray-600 text-sm md:text-base">{description}</p>
  </div>
);

function BranchRenewal() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />
      
      <main className="flex-grow flex items-center justify-center px-4 py-8 md:py-12">
        <div className="container mx-auto max-w-4xl">
          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="main_text text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 md:mb-6">
              Branch Renewal
              <br className="md:hidden" />
              <span className="block mt-2">Coming Soon</span>
            </h1>

            <p className="main_text text-lg sm:text-xl md:text-2xl mb-8 max-w-2xl mx-auto px-4">
              We're working on bringing you an enhanced branch renewal experience.
              Stay tuned for streamlined processes and improved features to better
              serve our community.
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 mb-8 md:mb-12 px-4">
              <StatCard title="24/7" description="Online Access" />
              <StatCard title="100%" description="Digital Process" />
              <StatCard 
                title="Fast" 
                description="Processing Time" 
                className="sm:col-span-2 lg:col-span-1"
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-4 justify-center px-4">
              <Link to="/about/contact" className="w-full sm:w-auto">
                <GlassButton>Contact Support</GlassButton>
              </Link>
              <Link to="/" className="w-full sm:w-auto">
                <GlassButton>Return to Home</GlassButton>
              </Link>
            </div>
          </motion.div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
}

export default BranchRenewal;