import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const MyCalendar = ({ events }) => {
  const formattedEvents = events.map(event => ({
    title: event.summary,
    start: new Date(event.start.dateTime || event.start.date),
    end: new Date(event.end.dateTime || event.end.date),
    allDay: !event.start.dateTime,
    desc: event.description,
    location: event.location
  }));

  const EventComponent = ({ event }) => (
    <div className="text-sm">
      <strong>{event.title}</strong>
      {event.location && <div className="text-xs">{event.location}</div>}
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-lg p-4">
      <Calendar
        localizer={localizer}
        events={formattedEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '80vh' }}
        components={{
          event: EventComponent
        }}
        eventPropGetter={() => ({
          className: 'bg-blue-600 text-white rounded-sm border-none'
        })}
        onSelectEvent={event => {
          if (event.desc) {
            alert(event.desc);
          }
        }}
        messages={{
          noEventsInRange: 'No events scheduled for this time period'
        }}
      />
    </div>
  );
};

export default MyCalendar;