import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import AdvocacyPhoto from '../../../images/Health_WWD.jpg';
import ActionPhoto from "../../../images/Peace_WWD.JPG";
import HeroPhoto from "../../../images/Hero_WWD.jpg";

const WhatWeDo = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />
      <main className="flex-grow">
        <div className="container mx-auto px-4 sm:px-6 py-6 sm:py-8 md:py-12 lg:py-16">
          <motion.div 
            className="max-w-4xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {/* Header Section */}
            <div className="text-center mb-8 md:mb-16">
              <span className="inline-block text-xs sm:text-sm font-semibold tracking-wider text-gray-600 mb-2 sm:mb-3">
                OUR IMPACT
              </span>
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-4 sm:mb-6 md:mb-8 main_text">
                What We Do
              </h1>
              <div className="h-1 w-16 md:w-20 bg-current mx-auto main_text mb-6 md:mb-8"></div>
              {/* Hero Image */}
              <div className="rounded-lg overflow-hidden mb-6 md:mb-8">
                <img 
                  src={HeroPhoto}
                  alt="Smiling refugee children representing hope and future"
                  className="w-full object-contain md:object-cover max-h-[55vh]"
                />
              </div>
            </div>

            {/* Introduction Card */}
            <div className="bg-gray-50 p-4 sm:p-6 md:p-8 rounded-lg mb-8 md:mb-12 shadow-sm border border-gray-100">
              <p className="text-base sm:text-lg md:text-xl font-medium text-center text-gray-700">
                Through Education, Action, and Advocacy, we create lasting impact in refugee communities.
              </p>
            </div>

            {/* Main Content Sections */}
            <div className="space-y-8 md:space-y-16">
              {/* Education Section */}
              <div className="mb-8 md:mb-16">
                <div className="flex flex-col lg:flex-row gap-4 md:gap-8 items-stretch">
                  <div className="flex-1 w-full">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-[#4FCFBC]">Education</h2>
                    <div className="text-gray-700 leading-relaxed space-y-4 md:space-y-6 text-base sm:text-lg">
                      <div className="text-sm sm:text-base md:text-lg space-y-6">
                        <p>At Students for Refugees, we believe education is a cornerstone of empowerment. Our mission begins in classrooms and lecture halls, where we shine a light on the realities of the global refugee crisis. Each chapter is charged with the responsibility of creating and delivering lectures on pressing issues that shape the lives of refugees worldwide. Through these efforts, we cultivate a generation of students who are not only informed but who carry the cause of advocacy with them wherever they go.</p>
                        <p>But education doesn't stop at awareness—it thrives in action. In 2023, we launched a tutoring program with a small cohort of 15 refugee children, aiming to meet their academic needs. Today, that program has grown exponentially, with over 50 tutors providing more than 1,500 hours of personalized assistance. These moments are not one-sided; they are an exchange. Out student tutors learn the rich histories and cultures of their students, while refugee children gain the tools they need to navigate and thrive in a new world.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Action Section */}
              <div className="mb-8 md:mb-16">
                <div className="flex flex-col lg:flex-row gap-4 md:gap-8 items-stretch">
                  <div className="flex-1 w-full">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-[#4FCFBC]">Action</h2>
                    <div className="text-gray-700 leading-relaxed space-y-4 md:space-y-6 text-base sm:text-lg">
                      <div className="text-sm sm:text-base md:text-lg space-y-6">
                        <p>We are not content to work from the sidelines. At Students for Refugees, we believe in the power of direct action. One of our most impactful initiatives is the delivery of essential goods—non-perishable food, clothing, and hygiene kits—straight to the doorsteps of vulnerable refugee families. Funded entirely through donations and bolstered by our zero-overhead policy, these efforts reflect our commitment to transparency and tangible impact.</p>
                        <p>In Houston, Texas, we lend our hands and hearts to the Refugee Peace Farm. Here, refugee women—many of whom once tilled the land in their home countries—regain the tools and space to cultivate a new sense of belonging. Together, we help to maintain the community farm, nurturing not only crops but here the students who partake learn the resilience and spirit of those who work the soil.</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 flex">
                    <img 
                      src={ActionPhoto}
                      alt="Community members working at the Refugee Peace Farm"
                      className="rounded-3xl shadow-lg w-full object-cover"
                    />
                  </div>
                </div>
              </div>

              {/* Advocacy Section */}
              <div className="mb-8 md:mb-16">
                <div className="flex flex-col lg:flex-row-reverse gap-4 md:gap-8 items-stretch">
                  <div className="flex-1 w-full">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-[#4FCFBC]">Advocacy</h2>
                    <div className="text-gray-700 leading-relaxed space-y-4 md:space-y-6 text-base sm:text-lg">
                      <div className="text-sm sm:text-base md:text-lg space-y-6">
                        <p>Advocacy is the heartbeat of our work. We seek to amplify refugee voices and drive meaningful change through strategic partnerships and community events. From the annual Refugee Health Fair, where we collaborate by volunteering with the medical students of Baylor College of Medicine, UT McGovern, UH medical school, to the Refugee Mental Health and Wellness Conference, where we help to facilitate guest speakers and workshops, our mission is clear: to create awareness about the global refugee crisis and how students can be apart of the solution.</p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 flex">
                    <img 
                      src={AdvocacyPhoto}
                      alt="Health Fair event showing community impact"
                      className="rounded-3xl shadow-lg w-full object-cover"
                    />
                  </div>
                </div>
              </div>

              {/* Our Commitment Section */}
              <div className="mb-8 md:mb-16">
                <div className="flex flex-col lg:flex-row gap-4 md:gap-8 items-stretch">
                  <div className="flex-1 w-full">
                    <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-[#4FCFBC]">Our Commitment</h2>
                    <div className="text-gray-700 leading-relaxed space-y-4 md:space-y-6 text-base sm:text-lg">
                      <div className="text-sm sm:text-base md:text-lg space-y-6">
                        <p>Students for Refugees stands on three unshakable pillars: Education, Action, and Advocacy. Together, these principles guide our work as we strive to better the condition of refugees and, more specifically, refugee students. We believe in the potential of this generation—our peers—and we aim to empower them to rise.</p>
                        <p>We are ever-evolving, always seeking new opportunities to expand our impact. If you represent an organization and wish to partner with us, we invite you to connect through our contact page. Let us build something meaningful together for our future.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Impact Statistics */}
            {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 md:gap-8 mt-8 md:mt-16">
              <div className="text-center p-4 sm:p-6 rounded-lg bg-gray-50 shadow-sm">
                <div className="text-2xl sm:text-3xl font-bold main_text mb-2">1,500+</div>
                <div className="text-sm sm:text-base text-gray-600">Tutoring Hours</div>
              </div>
              <div className="text-center p-4 sm:p-6 rounded-lg bg-gray-50 shadow-sm">
                <div className="text-2xl sm:text-3xl font-bold main_text mb-2">50+</div>
                <div className="text-sm sm:text-base text-gray-600">Active Tutors</div>
              </div>
              <div className="text-center p-4 sm:p-6 rounded-lg bg-gray-50 shadow-sm sm:col-span-2 md:col-span-1">
                <div className="text-2xl sm:text-3xl font-bold main_text mb-2">15+</div>
                <div className="text-sm sm:text-base text-gray-600">Partner Organizations</div>
              </div>
            </div> */}
          </motion.div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default WhatWeDo;