import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Mail, MapPin } from "lucide-react";
import { FaInstagram, FaLinkedin } from 'react-icons/fa';


const Contact = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  });
  const [state, handleSubmit] = useForm("mpwarjnl");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    let successTimer, errorTimer;
    if (state.succeeded) {
      setShowSuccessMessage(true);
      successTimer = setTimeout(() => setShowSuccessMessage(false), 10000);
      resetForm();
    } else if (state.errors && state.errors.length > 0) {
      setShowErrorMessage(true);
      errorTimer = setTimeout(() => setShowErrorMessage(false), 10000);
    }
    return () => {
      clearTimeout(successTimer);
      clearTimeout(errorTimer);
    };
  }, [state.succeeded, state.errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormState({
      name: "",
      email: "",
      subject: "",
      message: ""
    });
  };

  const glassEffect = "backdrop-blur-lg bg-opacity-20 border border-white/20";

  return (
    <div>
      <div className="min-h-screen">
        <Navbar
          colorSFR="main_text"
          colorRest="text-[#9999A3]"
          colorBox="bg-black"
          colortextBox="text-white"
          colorhover="#464646"
        />

        <main className="flex-grow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h1 className="text-4xl md:text-5xl font-bold text-center mb-4 main_text">
              Get In Touch!
            </h1>
            <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto px-4">
              If you have any further questions, please don't hesitate 
              to leave us a message! We will do our best to get back to 
              you as soon as possible.
            </p>

            <div className="grid lg:grid-cols-12 gap-8">
              {/* Contact Information Card */}
              <div className="lg:col-span-4">
                <div className={`main_bg text-white rounded-xl p-8 h-full relative overflow-hidden ${glassEffect}`}>
                  <div className="relative z-10">
                    <h2 className="text-2xl font-semibold mb-6">
                      Contact Information
                    </h2>
                    <div className="space-y-6">
                      <div className="flex items-center space-x-4">
                        <Mail className="w-6 h-6" />
                        <a
                          href="mailto:hello@studentsforrefugees.org"
                          className="hover:underline text-sm md:text-base"
                        >
                          hello@studentsforrefugees.org
                        </a>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaInstagram className="w-6 h-6" />
                        <a
                          href="https://www.instagram.com/sfrnational/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline text-sm md:text-base"
                        >
                          @studentsforrefugees
                        </a>
                      </div>
                      <div className="flex items-center space-x-4">
                        <FaLinkedin className="w-6 h-6" />
                        <a
                          href="https://www.linkedin.com/company/studentsforrefugees"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline text-sm md:text-base"
                        >
                          Students For Refugees
                        </a>
                      </div>
                      <div className="flex items-center space-x-4">
                        <MapPin className="w-6 h-6" />
                        <span className="text-sm md:text-base">Texas, USA</span>
                      </div>
                    </div>
                  </div>
                  {/* Decorative circles */}
                  <div className="absolute bottom-0 right-0 w-60 h-60 rounded-full opacity-30 transform translate-x-20 translate-y-20 bg-white/30" />
                  {/* Top left circle - much larger and lighter */}
                  <div className="absolute top-0 left-0 w-48 h-48 rounded-full opacity-25 -translate-x-24 -translate-y-24 bg-white/20" />
                </div>
              </div>

              {/* Contact Form */}
              <div className="lg:col-span-8">
                <div className={`bg-white rounded-xl p-6 md:p-8 shadow-xl ${glassEffect}`}>
                  {showSuccessMessage && (
                    <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-lg">
                      Thanks for your message!
                    </div>
                  )}
                  {showErrorMessage && (
                    <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
                      There was a problem. Please try again later.
                    </div>
                  )}

                  <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid md:grid-cols-2 gap-6">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Your Name
                        </label>
                        <input
                          type="text"
                          name="name"
                          value={formState.name}
                          onChange={handleChange}
                          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-opacity-50 focus:ring-current focus:border-transparent transition-all duration-200 bg-white/50"
                          required
                          disabled={state.submitting}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Your Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          value={formState.email}
                          onChange={handleChange}
                          className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-opacity-50 focus:ring-current focus:border-transparent transition-all duration-200 bg-white/50"
                          required
                          disabled={state.submitting}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Your Subject
                      </label>
                      <input
                        type="text"
                        name="subject"
                        value={formState.subject}
                        onChange={handleChange}
                        className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-opacity-50 focus:ring-current focus:border-transparent transition-all duration-200 bg-white/50"
                        required
                        disabled={state.submitting}
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Message
                      </label>
                      <textarea
                        name="message"
                        value={formState.message}
                        onChange={handleChange}
                        rows={4}
                        className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-opacity-50 focus:ring-current focus:border-transparent transition-all duration-200 bg-white/50"
                        required
                        disabled={state.submitting}
                        placeholder="Write here your message"
                      />
                    </div>

                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />

                    <button
                      type="submit"
                      disabled={state.submitting}
                      className="w-full btn-main py-4 px-6 rounded-lg text-white text-lg font-medium transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;