import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import Humza from "../../../images/Humza.webp";
import Kareem from "../../../images/Kareem.webp";
import Yazan from "../../../images/Yazan.webp";
import Deniz from "../../../images/Deniz.webp";
import Carnegie from "../../../images/SFR_Carnegie_Logo.svg";
import Bellaire from "../../../images/SFR_Bellaire_Logo.svg";
import DeBakey from "../../../images/SFR_DeBakey_Logo.svg";
import Tamu from "../../../images/SFR_Tamu_Logo.svg";
// import Baylor from "../../../images/SFR_Baylor_Logo.svg";
import Challenge from "../../../images/SFR_Challenge_Logo.svg";
import UH from "../../../images/SFR_UH_Logo.svg";
import Westside from "../../../images/SFR_Westside_Logo.svg";
import Nabila from "../../../images/Nabila.png";
import Katharine from "../../../images/Katharine.png";
import Aisha from "../../../images/Aisha.jpg"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Ali from '../../../images/Ali.webp';
import Asad from '../../../images/Asad.webp';
import Hira from '../../../images/Hira.webp';
import Homeschool from '../../../images/SFR_Homeschool_Logo.svg'
import { motion } from 'framer-motion';
import 'react-lazy-load-image-component/src/effects/blur.css';


function Team() {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const boardMembers = [
    {
      src: Katharine,
      name: "Katharine Gratwick",
      title: "Development Economist",
      bio: "Katharine Gratwick is a development economist living and working in Houston, TX. She specializes in sub-Saharan African electricity policy. She joined SFR in 2023 and is thrilled to learn and work alongside a group of committed and concerned individuals who believe our strength lies in our diversity, our compassion, and ultimately our ability to serve one another."
    },
    {
      src: Aisha,
      name: "Aisha Koroma",
      title: "Mental Health & Social Services Professional",
      bio: "Aisha Koroma, born in Sierra Leone, is a trauma-responsive mental health and social services professional dedicated to empowering marginalized populations. A war survivor and refugee, she migrated to the US and now aids refugee integration. A licensed therapist with degrees in psychology and counseling, she has spearheaded mental health initiatives, co-authored works on refugee experiences, and developed innovative GBV programs. Aisha is a renowned advocate for refugee voices globally."
    },
    {
      src: Nabila,
      name: "Nabila Ansar",
      title: "Managing Attorney",
      bio: "Nabila is the Managing Attorney of the Law Office of Nabila Ansari. She assists her clients in resolving complex legal problems in the areas of business and corporate governance, risk mitigation and contracts. Nabila is a longtime Houston resident and has been active in supporting her community through volunteer work, including refugee support services, for many years. She joined the board in 2024."
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        {/* Executive Team Section */}
        <motion.section 
          className="mb-32"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold main_text">Meet the Team</h1>
            <div className="h-1 w-20 main_bg mx-auto mt-4"></div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-16 md:grid-cols-2 lg:grid-cols-4">
            {[
              { src: Humza, name: "Imran Humza Hanif", title: "President" },
              { src: Deniz, name: "Deniz Çalışkan", title: "Vice President" },
              { src: Hira, name: "Hira Malik", title: "Secretary" },
              { src: Ali, name: "Ali Faruqui", title: "Treasurer" },
              { src: Yazan, name: "Yazan Zarka", title: "International Relations" },
              { src: Asad, name: "As'ad Ansari", title: "Outreach Officer" },
              { src: Kareem, name: "Kareem Kabbani", title: "Web Developer" },
            ].map((member, index) => (
              <motion.div 
                key={index}
                className="group flex flex-col items-center"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <div className="w-72 h-72 aspect-square rounded-2xl bg-white shadow-lg overflow-hidden">
                  <LazyLoadImage
                    src={member.src}
                    alt={member.name}
                    className="w-72 h-72 object-cover"
                    effect="blur"
                  />
                </div>
                <h3 className="text-lg font-semibold main_text mt-4">{member.name}</h3>
                <p className="text-gray-500">{member.title}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Board Members Section */}
        <motion.section 
          className="mb-32"
          initial="initial"
          animate="animate"
          variants={fadeIn}
        >
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold main_text">Meet the Board</h2>
            <div className="h-1 w-20 main_bg mx-auto mt-4"></div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {boardMembers.map((member, index) => (
              <motion.div 
                key={index}
                className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <div className="p-6 flex flex-col md:flex-row gap-6">
                  <div className="flex-shrink-0">
                    <img
                      src={member.src}
                      className="h-48 w-48 rounded-xl object-cover mx-auto"
                      alt={member.name}
                    />
                  </div>
                  <div className="flex-grow">
                    <h3 className="text-xl font-bold main_text mb-1">{member.name}</h3>
                    <p className="text-sm font-semibold text-gray-600 mb-4">{member.title}</p>
                    <p className="text-gray-600 leading-relaxed">{member.bio}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Branches Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <div className="text-center mb-16">
            <h2 className="text-4xl md:text-5xl font-bold main_text">Active Branches</h2>
            <div className="h-1 w-20 main_bg mx-auto mt-4"></div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
            {[
              { src: Carnegie, name: "Carnegie Vanguard" },
              { src: Bellaire, name: "Bellaire" },
              { src: DeBakey, name: "DeBakey" },
              { src: Tamu, name: "Texas A&M" },
              // { src: Baylor, name: "Baylor Medicine" },
              { src: Challenge, name: "Challenge" },
              { src: UH, name: "University of Houston" },
              { src: Westside, name: "Westside" },
              { src: Homeschool, name: "Homeschool" }
            ].map((branch, index) => (
              <motion.div 
                key={index}
                className="group flex flex-col items-center"
                whileHover={{ y: -5 }}
                transition={{ duration: 0.2 }}
              >
                <div className="w-32 h-32 rounded-2xl overflow-hidden bg-white shadow-lg">
                  <img
                    src={branch.src}
                    className="w-full h-full object-cover"
                    alt={branch.name}
                  />
                </div>
                <h3 className="text-sm main_text mt-4">
                  {branch.name}
                </h3>
              </motion.div>
            ))}
          </div>
        </motion.section>
      </div>

      <Footer />
    </div>
  );
}

export default Team;