import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  ExternalLink,
  Globe,
  BookOpen,
  ArrowRight,
  Newspaper,
  Building2,
  GraduationCap,
  Network,
  FileText,
  Landmark
} from "lucide-react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

// Helper function to choose icon based on organization type
const getOrganizationIcon = (organization) => {
  if (
    organization.includes("UNHCR") ||
    organization.includes("Holocaust Museum")
  ) {
    return Building2;
  }
  if (
    organization.includes("CNN") ||
    organization.includes("Washington Post") ||
    organization.includes("The Conversation")
  ) {
    return Newspaper;
  }
  if (organization.includes("Origins") || organization.includes("Brookings")) {
    return GraduationCap;
  }
  if (organization === "Holocaust Museum") {
    return Landmark;
  }
  return FileText;
};

const ResourceCard = ({ organization, links, onExpand, isExpanded }) => {
  const Icon = getOrganizationIcon(organization);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onClick={onExpand}
      className="w-full bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
    >
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Icon className="w-6 h-6 main_text" />
            <h2 className="text-xl md:text-2xl font-bold main_text">
              {organization}
            </h2>
          </div>
          <motion.div
            animate={{ rotate: isExpanded ? 90 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ArrowRight className="w-5 h-5 main_text" />
          </motion.div>
        </div>

        <motion.div
          initial={false}
          animate={{
            height: isExpanded ? "auto" : 0,
            opacity: isExpanded ? 1 : 0,
            marginTop: isExpanded ? 16 : 0
          }}
          transition={{ duration: 0.3 }}
          className="overflow-hidden"
        >
          <div className="space-y-3">
            {links.map((link, index) => (
              <motion.div
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center gap-2 group"
                onClick={(e) => e.stopPropagation()}
              >
                <ExternalLink className="w-4 h-4 text-gray-400 group-hover:main_text transition-colors duration-200 flex-shrink-0" />
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:main_text text-sm md:text-base transition-colors duration-200 break-all"
                >
                  {link}
                </a>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

const Quote = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8 }}
    className="relative w-full max-w-4xl mx-auto px-4 py-12"
  >
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden">
      <div className="absolute -top-20 -left-20 w-40 h-40 lg:w-56 lg:h-56 rounded-full btn-main opacity-5" />
      <div className="absolute bottom-1/4 -right-20 w-40 h-40 lg:w-56 lg:h-56 rounded-full btn-main opacity-5" />
    </div>

    <div className="relative bg-white rounded-2xl shadow-lg p-8 md:p-12">
      <BookOpen className="w-8 h-8 main_text mb-6" />
      <blockquote className="text-2xl md:text-3xl font-bold main_text mb-4">
        "Education is the most powerful weapon which you can use to change the
        world."
      </blockquote>
      <cite className="text-gray-600 text-lg">— Nelson Mandela</cite>
    </div>
  </motion.div>
);

const MigrationPolicy = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const resources = [
    {
      organization: "Brookings.Edu",
      links: [
        "https://www.brookings.edu/blog/order-from-chaos/2020/11/06/the-eus-new-pact-on-migration-and-asylum-is-missing-a-true-foundation/"
      ]
    },
    {
      organization: "The Conversation",
      links: [
        "https://theconversation.com/new-eu-migration-pact-driven-by-political-compromise-not-humanitarianism-146862"
      ]
    },
    {
      organization: "Origins",
      links: [
        "https://origins.osu.edu/article/refugees-or-immigrants-migration-crisis-europe-historical-perspective"
      ]
    },
    {
      organization: "CNN",
      links: [
        "https://www.cnn.com/2017/01/30/politics/trump-immigration-ban-refugees-trnd/index.html"
      ]
    },
    {
      organization: "Washington Post",
      links: [
        "https://www.washingtonpost.com/graphics/world/historical-migrant-crisis/"
      ]
    },
    {
      organization: "Holocaust Museum",
      links: [
        "https://exhibitions.ushmm.org/americans-and-the-holocaust/how-many-refugees-came-to-the-united-states-from-1933-1945"
      ]
    },
    {
      organization: "UNHCR",
      links: ["https://www.unhcr.org/gcr/GCR_English.pdf"]
    }
  ];

  return (
    <div>
      <div className="min-h-screen bg-gray-50">
        <Navbar
          colorSFR="main_text"
          colorRest="text-[#9999A3]"
          colorBox="bg-black"
          colortextBox="text-white"
          colorhover="#464646"
        />

        <Quote />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="max-w-4xl mx-auto px-4 py-12"
        >
          <div className="space-y-4">
            {resources.map((resource, index) => (
              <ResourceCard
                key={index}
                {...resource}
                isExpanded={expandedCard === index}
                onExpand={() =>
                  setExpandedCard(expandedCard === index ? null : index)
                }
              />
            ))}
          </div>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default MigrationPolicy;
