import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const BranchCreation = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    institution: "",
    motivation: "",
    refugeeIssues: "",
    leadership: "",
    membershipInterest: "",
    facultySupport: "",
    activities: "",
    communityEngagement: "",
    localCollaborations: "",
    funding: "",
    supportNeeded: "",
    commitment: false
  });

  const [touched, setTouched] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const GOOGLE_FORM_URL =
    "https://docs.google.com/forms/d/e/1FAIpQLSevIkFwoHr7UUzLdUKXvj78r9JJ5WV88_rp8-IQmvgg9p-u-A/formResponse";

  const GOOGLE_FORM_FIELDS = {
    name: "entry.1799182697",
    email: "entry.1547985899",
    phoneNumber: "entry.1166557629",
    institution: "entry.581641062",
    motivation: "entry.1526520824",
    refugeeIssues: "entry.109956558",
    leadership: "entry.1885662826",
    membershipInterest: "entry.392249357",
    facultySupport: "entry.1625851622",
    activities: "entry.585993317",
    communityEngagement: "entry.1485805692",
    localCollaborations: "entry.1382103954",
    funding: "entry.974061373",
    supportNeeded: "entry.2118416777",
    commitment: "entry.987179961"
  };

  const validateField = (name, value) => {
    switch (name) {
      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Please enter a valid email address";
      case "phoneNumber":
        if (!value) {
          return "Phone number is required";
        }
        if (!isValidPhoneNumber(value)) {
          return "Please enter a valid phone number for the selected country";
        }
        return "";
      case "commitment":
        return value ? "" : "You must agree to the commitment statement";
      default:
        return value.toString().trim() ? "" : "This field is required";
    }
  };

  const phoneInputClassName = `
    w-full px-4 py-3 border rounded-lg transition-all duration-200
    ${
      touched.phoneNumber && validationErrors.phoneNumber
        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
        : "border-gray-200 focus:ring-2 focus:ring-opacity-50 focus:ring-current focus:border-transparent"
    }
    ${isSubmitting ? "bg-gray-100" : "bg-white"}
  `;

  const handleBlur = (e) => {
    const { name, value, type, checked } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true
    }));

    const error = validateField(name, type === "checkbox" ? checked : value);
    setValidationErrors((prev) => ({
      ...prev,
      [name]: error
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormState((prev) => ({
      ...prev,
      [name]: newValue
    }));

    if (touched[name]) {
      const error = validateField(name, newValue);
      setValidationErrors((prev) => ({
        ...prev,
        [name]: error
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);

    // Validate all fields
    const errors = {};
    Object.entries(formState).forEach(([name, value]) => {
      const error = validateField(name, value);
      if (error) errors[name] = error;
    });

    setValidationErrors(errors);
    setTouched(
      Object.keys(formState).reduce((acc, key) => ({ ...acc, [key]: true }), {})
    );

    if (Object.keys(errors).length > 0) {
      setIsSubmitting(false);
      setShowErrorMessage(true);
      const firstError = document.querySelector('[aria-invalid="true"]');
      if (firstError) {
        firstError.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      return;
    }

    try {
      // Create form data object with Google Form field IDs
      const formData = new FormData();
      Object.entries(formState).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (key === "commitment") {
            formData.append(GOOGLE_FORM_FIELDS[key], value ? "Yes" : "");
          } else {
            formData.append(GOOGLE_FORM_FIELDS[key], value.toString());
          }
        }
      });

      await fetch(GOOGLE_FORM_URL, {
        method: "POST",
        body: formData,
        mode: "no-cors" // Required for submitting to Google Forms
      });

      // Since we're using no-cors, we won't get a proper response status
      // We'll assume success if no error is thrown
      setShowSuccessMessage(true);
      resetForm();
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      console.error("Submission error:", error);
      setShowErrorMessage(true);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 10000); // Hide after 10 seconds
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  useEffect(() => {
    if (showErrorMessage) {
      const timer = setTimeout(() => {
        setShowErrorMessage(false);
      }, 10000); // Hide after 10 seconds
      return () => clearTimeout(timer);
    }
  }, [showErrorMessage]);

  const resetForm = () => {
    setFormState({
      name: "",
      email: "",
      phoneNumber: "",
      institution: "",
      motivation: "",
      refugeeIssues: "",
      leadership: "",
      membershipInterest: "",
      facultySupport: "",
      activities: "",
      communityEngagement: "",
      localCollaborations: "",
      funding: "",
      supportNeeded: "",
      commitment: false
    });
    setTouched({});
    setValidationErrors({});
  };

  const inputClassName = (name) => `
    w-full px-4 py-3 border rounded-lg transition-all duration-200
    ${
      touched[name] && validationErrors[name]
        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
        : "border-gray-200 focus:ring-2 focus:ring-opacity-50 focus:ring-current focus:border-transparent"
    }
    ${isSubmitting ? "bg-gray-100" : "bg-white"}
  `;

  const glassEffect = "backdrop-blur-lg bg-opacity-20 border border-white/20";

  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />

      <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold main_text mb-4">
              Start a New Chapter
            </h1>
            <p className="text-gray-600 max-w-2xl mx-auto text-left">
              Thank you for your interest in starting a Students For Refugees
              chapter! Please complete this form to begin the process. We will
              review your application and contact you with next steps. Your
              application will be reviewed within 5-10 days. If your branch is
              approved, you will be contacted to schedule your interview. Please
              refer to our chapter charter below when responding to the form.
              <br />
              <a
                href="https://docs.google.com/document/d/1csPmPiM1iFq4hsZwlyW1sNezZ5idgXOCqUwF6WJeADk/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-sm md:text-base main_text font-bold"
              >
                Chapter Charter Document{" "}
              </a>
            </p>
          </div>

          <div
            className={`bg-white rounded-xl p-4 md:p-8 shadow-xl ${glassEffect}`}
          >
            {showSuccessMessage && (
              <div
                className="mb-6 p-4 bg-green-100 text-green-700 rounded-lg"
                role="alert"
              >
                Your chapter application has been successfully submitted! You
                will be contacted within 5-10 days regarding next steps.
              </div>
            )}

            {showErrorMessage && (
              <div
                className="mb-6 p-4 bg-red-100 text-red-700 rounded-lg"
                role="alert"
              >
                There was an error submitting your application. Please try again
                later or contact us directly if the problem persists.
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-8" noValidate>
              {/* Contact Information */}

              <section className="space-y-6">
                <h2 className="text-2xl font-semibold main_text">
                  Contact Information
                </h2>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Full Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formState.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={inputClassName("name")}
                    required
                    disabled={isSubmitting}
                    aria-invalid={touched.name && !!validationErrors.name}
                  />
                  {touched.name && validationErrors.name && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.name}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Email Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formState.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={inputClassName("email")}
                    required
                    disabled={isSubmitting}
                    aria-invalid={touched.email && !!validationErrors.email}
                  />
                  {touched.email && validationErrors.email && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.email}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Phone Number <span className="text-red-500">*</span>
                  </label>

                  <div className="relative">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      countrySelectProps={{
                        "aria-label": "Select country",
                        className: "absolute left-0 top-0 bottom-0"
                      }}
                      value={formState.phoneNumber}
                      onChange={(value) => {
                        setFormState((prev) => ({
                          ...prev,
                          phoneNumber: value
                        }));

                        // Validate on change if the field has been touched
                        if (touched.phoneNumber) {
                          const error = validateField("phoneNumber", value);
                          setValidationErrors((prev) => ({
                            ...prev,
                            phoneNumber: error
                          }));
                        }
                      }}
                      onBlur={() => {
                        // Mark as touched and validate on blur
                        setTouched((prev) => ({
                          ...prev,
                          phoneNumber: true
                        }));

                        const error = validateField(
                          "phoneNumber",
                          formState.phoneNumber
                        );
                        setValidationErrors((prev) => ({
                          ...prev,
                          phoneNumber: error
                        }));
                      }}
                      className={phoneInputClassName}
                      disabled={isSubmitting}
                    />
                  </div>

                  {touched.phoneNumber && validationErrors.phoneNumber && (
                    <p className="mt-1 text-sm text-red-500" role="alert">
                      {validationErrors.phoneNumber}
                    </p>
                  )}

                  <p className="mt-1 text-xs text-gray-500">
                    Select country and enter your full phone number
                  </p>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Institution Type <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="institution"
                    value={formState.institution}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={inputClassName("institution")}
                    required
                    disabled={isSubmitting}
                  >
                    <option value="">Select your institution type</option>
                    <option value="Middle School">Middle School</option>
                    <option value="High School">High School</option>
                    <option value="University">University</option>
                    <option value="Other">Other</option>
                  </select>
                  {touched.institution && validationErrors.institution && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.institution}
                    </p>
                  )}
                </div>
              </section>

              {/* Chapter Details */}
              <section className="space-y-6 pt-8">
                <h2 className="text-2xl font-semibold main_text">
                  Chapter Details
                </h2>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    What motivates you to start a Students for Refugees chapter?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="motivation"
                    value={formState.motivation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    className={inputClassName("motivation")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.motivation && validationErrors.motivation && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.motivation}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Are there specific refugee issues you are particularly
                    interested in supporting?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="refugeeIssues"
                    value={formState.refugeeIssues}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    className={inputClassName("refugeeIssues")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.refugeeIssues && validationErrors.refugeeIssues && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.refugeeIssues}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Who will lead the chapter? Include titles and names{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="leadership"
                    value={formState.leadership}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    placeholder="Example: President - Jane Doe, Vice President - John Smith"
                    className={inputClassName("leadership")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.leadership && validationErrors.leadership && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.leadership}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Do you have membership interest at your institution?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="membershipInterest"
                    value={formState.membershipInterest}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    placeholder="Detail current interest or plans to increase membership"
                    className={inputClassName("membershipInterest")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.membershipInterest &&
                    validationErrors.membershipInterest && (
                      <p className="mt-1 text-sm text-red-500">
                        {validationErrors.membershipInterest}
                      </p>
                    )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Do you have an established sponsor/faculty support?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <select
                    name="facultySupport"
                    value={formState.facultySupport}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={inputClassName("facultySupport")}
                    required
                    disabled={isSubmitting}
                  >
                    <option value="">Select an option</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {touched.facultySupport &&
                    validationErrors.facultySupport && (
                      <p className="mt-1 text-sm text-red-500">
                        {validationErrors.facultySupport}
                      </p>
                    )}
                </div>
              </section>

              {/* Planning & Implementation */}
              <section className="space-y-6 pt-8">
                <h2 className="text-2xl font-semibold main_text">
                  Planning & Implementation
                </h2>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    What unique activities, programs, or events do you envision?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="activities"
                    value={formState.activities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    className={inputClassName("activities")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.activities && validationErrors.activities && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.activities}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    How do you plan to engage students, faculty, and the broader
                    community? <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="communityEngagement"
                    value={formState.communityEngagement}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    className={inputClassName("communityEngagement")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.communityEngagement &&
                    validationErrors.communityEngagement && (
                      <p className="mt-1 text-sm text-red-500">
                        {validationErrors.communityEngagement}
                      </p>
                    )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Are there any local refugee communities or organizations
                    that you would aim to collaborate with?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="localCollaborations"
                    value={formState.localCollaborations}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    className={inputClassName("localCollaborations")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.localCollaborations &&
                    validationErrors.localCollaborations && (
                      <p className="mt-1 text-sm text-red-500">
                        {validationErrors.localCollaborations}
                      </p>
                    )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    How do you plan to fund chapter activities?{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="funding"
                    value={formState.funding}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    placeholder="Detail your funding plans (fundraisers, school grants, donations, etc.). Note: $80 annual chapter dues required."
                    className={inputClassName("funding")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.funding && validationErrors.funding && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.funding}
                    </p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    What support or resources do you need from the national
                    team? <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="supportNeeded"
                    value={formState.supportNeeded}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    rows={4}
                    className={inputClassName("supportNeeded")}
                    required
                    disabled={isSubmitting}
                  />
                  {touched.supportNeeded && validationErrors.supportNeeded && (
                    <p className="mt-1 text-sm text-red-500">
                      {validationErrors.supportNeeded}
                    </p>
                  )}
                </div>
              </section>

              {/* Commitment Statement */}
              <div className="pt-8">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="commitment"
                      name="commitment"
                      type="checkbox"
                      checked={formState.commitment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={`focus:ring-current h-4 w-4 text-current border-gray-300 rounded
                        ${
                          touched.commitment && validationErrors.commitment
                            ? "border-red-500"
                            : ""
                        }`}
                      required
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="ml-3">
                    <label
                      htmlFor="commitment"
                      className="text-sm text-gray-700"
                    >
                      I understand that starting a Students for Refugees chapter
                      is a commitment to uphold the organization's mission and
                      to engage in meaningful, respectful advocacy for refugees.{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    {touched.commitment && validationErrors.commitment && (
                      <p className="mt-1 text-sm text-red-500">
                        {validationErrors.commitment}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full btn-main py-4 px-6 rounded-lg text-white text-lg font-medium 
                  transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg mt-8
                  ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                {isSubmitting ? "Submitting..." : "Submit Chapter Application"}
              </button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BranchCreation;
