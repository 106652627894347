  import React, { useState, useEffect } from "react";
  import { useNavigate, useParams, useLocation } from "react-router-dom";
  import Navbar from "../../components/Navbar/Navbar";
  import Footer from "../../components/Footer/Footer";
  import { LazyLoadImage } from "react-lazy-load-image-component";
  import "react-lazy-load-image-component/src/effects/blur.css";

  const IndividualEventGallery = () => {
    const navigate = useNavigate();
    const { eventName } = useParams();
    const location = useLocation();
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const folderId = location.state?.folderId;

    const apiUrl = process.env.REACT_APP_API_URL;


    useEffect(() => {
      if (folderId) {
        const fetchImages = async () => {
          try {
            const response = await fetch(
              `${apiUrl}/api/drive-photos?folderID=${folderId}&size=medium`
            );
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();
            setImages(data);
          } catch (error) {
            console.error("Error fetching images:", error);
            setError("Failed to load images");
          } finally {
            setLoading(false);
          }
        };
        fetchImages();
      }
    }, [folderId]);

    const goBack = () => navigate("/gallery/events");

    if (loading) {
      return (
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      );
    }

    if (error || !folderId) {
      return (
        <div>
          <Navbar
            colorSFR="main_text"
            colorRest="text-[#9999A3]"
            colorBox="bg-black"
            colortextBox="text-white"
            colorhover="hover:bg-[#464646]"
          />
          <div className="container mx-auto px-4 py-24">
            <button
              onClick={goBack}
              className="btn-main text-white mb-8 font-bold py-2 px-4 rounded-full"
            >
              «Back to Event Gallery
            </button>
            <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">
              {error || "No images found"}
            </h1>
          </div>
          <Footer />
        </div>
      );
    }

    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar
          colorSFR="main_text"
          colorRest="text-[#9999A3]"
          colorBox="bg-black"
          colortextBox="text-white"
          colorhover="hover:bg-[#464646]"
        />
        <div className="container mx-auto px-4 py-24">
          <button
            onClick={goBack}
            className="btn-main text-white mb-8 font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
          >
            «Back to Event Gallery
          </button>
          <h1 className="text-3xl md:text-5xl font-bold text-center mb-12 main_text">
            {eventName} Gallery
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {images.map((image) => (
              <div
                key={image.id}
                className="aspect-square rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300 cursor-pointer"
                onClick={() => setSelectedImage(image)}
              >
                <LazyLoadImage
                  src={`${apiUrl}${image.thumbnailUrl}`}
                  alt={image.name}
                  className="w-full h-full object-cover"
                  effect="blur"
                  placeholderSrc="/placeholder-image.jpg"
                  width={400}
                  height={400}
                  loading="lazy"
                />
              </div>
            ))}
          </div>
          {images.length === 0 && (
            <div className="text-center text-gray-500 mt-8">
              No images found in this gallery
            </div>
          )}
        </div>

        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
            onClick={() => setSelectedImage(null)}
          >
            <div className="max-w-4xl w-full h-auto relative">
              <button
                className="absolute top-4 right-4 text-white text-xl bg-black bg-opacity-50 rounded-full w-10 h-10 flex items-center justify-center"
                onClick={() => setSelectedImage(null)}
              >
                ×
              </button>
              <LazyLoadImage
                src={`${apiUrl}${selectedImage.thumbnailUrl}`}
                alt={selectedImage.name}
                className="w-full h-auto max-h-[90vh] object-contain rounded-lg"
                effect="blur"
              />
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  };

  export default IndividualEventGallery;
