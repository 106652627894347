import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { FaHandHoldingHeart, FaBookReader, FaMicrophone } from "react-icons/fa";
import RandomImageGallery from "../../components/RandomGallery.js/RandomGallery";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const GlassButton = ({ children, className = "", onClick = () => {}, variant = "default" }) => {
  const baseStyles = "backdrop-blur-md transition-all duration-300 rounded-full font-bold text-sm sm:text-base flex items-center justify-center w-full";
  
  const variants = {
    default: "bg-opacity-10 border border-current hover:bg-opacity-20 main_text",
    solid: "btn-main text-white hover:scale-105"
  };

  return (
    <button
      onClick={onClick}
      className={`
        ${baseStyles}
        ${variants[variant]}
        ${className}
        transform hover:-translate-y-1 hover:shadow-lg py-2.5 px-5 sm:py-4 sm:px-8
      `}
    >
      {children}
    </button>
  );
};

function Donate() {
  const navigateToExternalPage = () => {
    window.open(
      "https://buy.stripe.com/6oEfZ622UcD48ak4gg",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const impactAreas = [
    {
      icon: <FaHandHoldingHeart className="w-8 h-8 sm:w-12 sm:h-12 main_text mb-4" />,
      title: "Emergency Relief",
      description:
        "Providing essential supplies including food, hygiene products, and clothing to refugees in immediate need."
    },
    {
      icon: <FaBookReader className="w-8 h-8 sm:w-12 sm:h-12 main_text mb-4" />,
      title: "Education Support",
      description:
        "Offering tutoring, language assistance, and educational resources to help refugee students thrive in their new environment."
    },
    {
      icon: <FaMicrophone className="w-8 h-8 sm:w-12 sm:h-12 main_text mb-4" />,
      title: "Speaker Series",
      description:
        "Hosting refugee speakers and organizing events to share personal stories, raise awareness, and foster understanding of refugee experiences."
    }
  ];

  return (
    <div className="min-h-screen max-w-[100vw] overflow-x-hidden">
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />

      {/* Hero Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12 sm:mb-20 container mx-auto px-3 sm:px-6 lg:px-8">
        <motion.div
          className="mt-12 sm:mt-24 lg:mt-32"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h1 className="main_text text-3xl sm:text-5xl md:text-6xl font-bold leading-tight mb-6 sm:mb-8">
            EMPOWER CHANGE,
            <br />
            ONE DONATION
            <br />
            AT A TIME
          </h1>
          <div className="max-w-xl">
            <p className="main_text text-base sm:text-lg leading-relaxed mb-6 sm:mb-8 max-w-[42ch]">
              Join us in creating lasting impact for refugee families. Your
              generosity directly supports essential services, education, and
              community integration programs.
            </p>
            <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 w-full sm:w-auto">
              <GlassButton
                variant="solid"
                onClick={navigateToExternalPage}
                className="w-full sm:w-[200px]"
              >
                Donate Now →
              </GlassButton>
              <Link to="/impact" className="w-full sm:w-[200px]">
                <GlassButton variant="default" className="w-full">
                  See Our Impact
                </GlassButton>
              </Link>
            </div>
          </div>
        </motion.div>

        <div className="mt-8 lg:mt-16 flex flex-col">
          <div className="mt-8 px-3 sm:px-0">
            <RandomImageGallery/>
          </div>
          <div className="p-4 sm:p-6 bg-gray-50 rounded-xl mt-4">
            <p className="main_text text-base sm:text-lg leading-relaxed">
              <strong className="text-lg sm:text-xl">100% Donation Impact</strong>
              <br />
              Every dollar you donate goes directly to supporting refugee
              families. We maintain complete transparency in our operations,
              ensuring your contribution creates maximum impact.
            </p>
          </div>
        </div>
      </div>

      {/* Impact Stats Section */}
      <section className="py-12 sm:py-16 bg-gray-50">
        <div className="container mx-auto px-3 sm:px-6 lg:px-8">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold main_text mb-12 sm:mb-16 text-center">
            Your Support Creates Real Change
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 mb-12 sm:mb-16">
            <div className="text-center p-4 sm:p-6 bg-white rounded-xl shadow-sm">
              <h3 className="text-3xl sm:text-4xl font-bold main_text mb-2">1,000+</h3>
              <p className="text-lg sm:text-xl main_text">Hours of Tutoring</p>
              <p className="mt-4 text-sm sm:text-base text-gray-600">
                Helping refugee students excel in their education
              </p>
            </div>

            <div className="text-center p-4 sm:p-6 bg-white rounded-xl shadow-sm">
              <h3 className="text-3xl sm:text-4xl font-bold main_text mb-2">4,000+</h3>
              <p className="text-lg sm:text-xl main_text">Refugees Supported</p>
              <p className="mt-4 text-sm sm:text-base text-gray-600">
                Direct aid and resources provided to families
              </p>
            </div>

            <div className="text-center p-4 sm:p-6 bg-white rounded-xl shadow-sm">
              <h3 className="text-3xl sm:text-4xl font-bold main_text mb-2">100+</h3>
              <p className="text-lg sm:text-xl main_text">Active Members</p>
              <p className="mt-4 text-sm sm:text-base text-gray-600">
                Dedicated volunteers creating change
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How We Help Section */}
      <section className="py-12 sm:py-16">
        <div className="container mx-auto px-3 sm:px-6 lg:px-8">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold main_text mb-12 sm:mb-16 text-center">
            How Your Donation Makes an Impact
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12 sm:mb-16">
            {impactAreas.map((area, index) => (
              <div key={index} className="text-center p-4 sm:p-6">
                <div className="flex justify-center">{area.icon}</div>
                <h3 className="text-lg sm:text-xl font-bold main_text mb-4">
                  {area.title}
                </h3>
                <p className="text-sm sm:text-base text-gray-600">{area.description}</p>
              </div>
            ))}
          </div>

          {/* Testimonial */}
          <div className="max-w-3xl mx-auto text-center px-4">
            <blockquote className="text-lg sm:text-xl italic main_text">
              "The support from Students for Refugees has been life-changing for
              our family. The educational assistance and community support have
              helped us build a new life here."
            </blockquote>
            <p className="mt-4 font-medium text-sm sm:text-base">— Recent Program Participant</p>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-12 sm:py-16 bg-gray-50">
        <div className="container mx-auto px-3 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold main_text mb-6 sm:mb-8">
            Ready to Make a Difference?
          </h2>
          <p className="text-lg sm:text-xl main_text mb-6 sm:mb-8 max-w-2xl mx-auto">
            Your donation today helps create a better tomorrow for refugee
            families. Join our community of supporters.
          </p>
          <GlassButton
            variant="solid"
            onClick={navigateToExternalPage}
            className="w-full sm:w-[200px] mx-auto"
          >
            Donate Now →
          </GlassButton>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Donate;