import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const GlassButton = ({ children, className = "", onClick = () => {} }) => (
  <button
    onClick={onClick}
    className={`
      backdrop-blur-md 
      bg-opacity-10 
      border border-current
      main_text
      hover:bg-opacity-20 
      transition-all 
      duration-300 
      rounded-full 
      font-bold 
      text-base
      md:text-lg 
      py-3
      md:py-4 
      px-6
      md:px-8
      w-full
      sm:w-auto
      transform 
      hover:-translate-y-1 
      hover:shadow-lg
      ${className}
    `}
  >
    {children}
  </button>
);

const StatCard = ({ number, description }) => (
  <div className="flex flex-col items-center justify-center p-4 md:p-6">
    <h3 className="text-4xl md:text-5xl font-bold main_text mb-2">{number}</h3>
    <p className="text-gray-600 text-lg md:text-xl text-center">{description}</p>
  </div>
);

function Impact() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />
      
      <main className="flex-grow flex items-start md:items-center justify-center px-4 py-8 md:py-12">
        <div className="container mx-auto max-w-4xl">
          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {/* Header Section */}
            <div className="mb-8 md:mb-12">
              <h1 className="main_text text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-6">
                Coming Soon
              </h1>

              <p className="main_text text-lg sm:text-xl md:text-2xl max-w-2xl mx-auto px-4 leading-relaxed">
                We're currently gathering stories and data about our impact on
                refugee communities. Check back soon to see how your support is
                making a difference.
              </p>
            </div>

            {/* Stats Section */}
            <div className="space-y-12 md:space-y-16 mb-12">
              <StatCard 
                number="1,000+" 
                description="Hours of Tutoring"
              />
              
              <StatCard 
                number="4,000+" 
                description="Refugees Supported"
              />
              
              <StatCard 
                number="100+" 
                description="Active Members"
              />
            </div>

            {/* CTA Section */}
            <div className="flex flex-col sm:flex-row gap-4 justify-center px-4">
              <Link to="/donate" className="w-full sm:w-auto">
                <GlassButton>Support Our Mission</GlassButton>
              </Link>
              <Link to="/volunteer/calendar" className="w-full sm:w-auto">
                <GlassButton>Get Involved</GlassButton>
              </Link>
            </div>
          </motion.div>
        </div>
      </main>
      
      <Footer />
    </div>
  );
}

export default Impact;