import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../../components/Footer/Footer';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NewsletterGallery = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchNewsletters = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/newsletters`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setNewsletters(data);
      } catch (error) {
        console.error('Error fetching newsletters:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNewsletters();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchNewsletters();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return (
    <div className="min-h-screen">
      <Navbar 
        colorSFR="main_text" 
        colorRest="text-[#9999A3]" 
        colorBox="bg-black" 
        colortextBox="text-white" 
        colorhover="#464646" 
      />
      
      <div className="flex-grow flex flex-col items-center justify-center px-4 py-8 w-full mb-24">
        <h1 className="text-5xl lg:text-6xl font-bold main_text mt-24 mb-12 text-center">
          Newsletters
        </h1>

        {loading ? (
          <div className="flex flex-col items-center justify-center h-[60vh]">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            <p className="mt-4 text-xl text-gray-600">Loading newsletters...</p>
          </div>
        ) : (
          <div className="w-full max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {newsletters.map((newsletter) => (
                <Link 
                  key={newsletter.name}
                  to={`/gallery/newsletters/${encodeURIComponent(newsletter.name)}`}
                  state={{ pdfId: newsletter.pdfId }}
                  className="block w-full transition duration-300 hover:scale-105"
                >
                  <div className="bg-white rounded-lg overflow-hidden shadow-md">
                    {newsletter.thumbnailId ? (
                      <div className="w-full aspect-[4/3]">
                        <LazyLoadImage
                          alt={newsletter.name}
                          effect="blur"
                          src={`${apiUrl}/api/image/${newsletter.thumbnailId}`}
                          className="w-full h-full object-cover"
                          placeholderSrc="/placeholder-newsletter.png"
                        />
                      </div>
                    ) : (
                      <div className="w-full aspect-[4/3] bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500">No thumbnail available</span>
                      </div>
                    )}
                    <div className="p-4">
                      <h2 className="text-xl text-[#78CDCD] font-medium">{newsletter.name}</h2>
                      <p className="text-gray-500 text-sm mt-1">Newsletter</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default NewsletterGallery;