import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion, AnimatePresence } from "framer-motion";
import "react-lazy-load-image-component/src/effects/blur.css";

const RandomImageGallery = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchRandomImage = async () => {
    try {
      const foldersResponse = await fetch(
        `${apiUrl}/api/drive-folders`
      );
      if (!foldersResponse.ok) throw new Error("Failed to fetch folders");
      const folders = await foldersResponse.json();

      const randomFolder = folders[Math.floor(Math.random() * folders.length)];
      const imagesResponse = await fetch(
        `${apiUrl}/api/drive-photos?folderID=${randomFolder.id}&size=medium`
      );

      if (!imagesResponse.ok) throw new Error("Failed to fetch images");
      const images = await imagesResponse.json();

      if (images.length > 0) {
        const randomImage = images[Math.floor(Math.random() * images.length)];
        // Preload the image before returning it
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.src = `${apiUrl}${randomImage.thumbnailUrl}`;
          img.onload = resolve;
          img.onerror = reject;
        });
        return randomImage;
      }
      return null;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;
    
    const loadFirstImage = async () => {
      try {
        const image = await fetchRandomImage();
        if (mounted && image) {
          setCurrentImage(image);
          setLoading(false);
        }
      } catch (error) {
        if (mounted) {
          setError("Failed to load random image");
          setLoading(false);
        }
      }
    };

    loadFirstImage();

    const rotationInterval = setInterval(async () => {
      if (mounted) {
        try {
          const newImage = await fetchRandomImage();
          if (mounted && newImage) {
            setCurrentImage(newImage);
          }
        } catch (error) {
          console.error("Error rotating image:", error);
        }
      }
    }, 5000);

    return () => {
      mounted = false;
      clearInterval(rotationInterval);
    };
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center mb-4">
      <div className="w-[500px]">
        <AnimatePresence mode="wait">
          {currentImage && (
            <motion.div
              key={currentImage.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="w-full h-[44vh] shadow-lg rounded-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 ease-in-out bg-gray-100"
            >
              <LazyLoadImage
                src={`${apiUrl}${currentImage.thumbnailUrl}`}
                alt="Random gallery image"
                className="w-full h-[44vh] object-cover"
                effect="blur"
                placeholderSrc="/placeholder-image.jpg"
                width={500}
                height={500}
                loading="lazy"
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default RandomImageGallery;