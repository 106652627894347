import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const OurVision = () => {
  return (
    <div>
      <Navbar
        colorSFR="main_text"
        colorRest="text-[#9999A3]"
        colorBox="bg-black"
        colortextBox="text-white"
        colorhover="#464646"
      />
      <div className="min-h-screen">
        <main className="flex-grow container mx-auto px-4 py-8 md:py-16">
          <div className="max-w-4xl mx-auto">
            {/* Header Section */}
            <div className="text-center mb-16">
              <span className="inline-block text-sm font-semibold tracking-wider text-gray-600 mb-3">
                OUR VISION
              </span>
              <h1 className="text-4xl md:text-6xl font-bold leading-tight mb-8">
                Making A Difference
              </h1>
              <div className="h-1 w-20 bg-black mx-auto"></div>
            </div>

            {/* Statistics Highlight */}
            <div className="bg-gray-50 p-6 md:p-8 rounded-lg mb-12 shadow-sm">
              <p className="text-lg md:text-xl font-medium text-center italic">
                As of 2024, the global refugee population has surged to over 35
                million (UNHCR), with more than half being children under 18.
              </p>
            </div>

            {/* Main Content */}
            <div className="prose prose-lg max-w-none mb-16">
              <p className="text-gray-700 leading-relaxed">
                The mission of Students for Refugees is to champion awareness
                and education about the ongoing refugee crisis caused by
                persecution, conflict, violence, and human rights violations. We
                strive to deepen understanding of migration
                issues—pre-migration, transmigration, and post-migration. Our
                organization empowers students to volunteer, advocate, and
                foster community participation for this vulnerable population,
                in collaboration with numerous agencies dedicated to assisting
                refugees.
              </p>
              <p className="text-gray-700 leading-relaxed mt-6">
                Through engagement, our students will gain invaluable insights
                into resilience and perseverance from the stories of the
                refugees they encounter. Our goal is to build a international
                network of students committed to promoting human rights, peace,
                and advocacy for displaced refugee children and their families,
                starting in Houston, which proudly hosts the largest number of
                resettled refugees in the United States.
              </p>
            </div>

            {/* Signature Section */}
            <div className="text-center border-t pt-8 mt-16">
              <p className="text-xl font-medium text-gray-900 mb-2">
                Imran Humza Hanif
              </p>
              <p className="text-gray-600">Founder and President</p>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default OurVision;
