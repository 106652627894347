import React, { useState } from "react";
import { CgMenuRightAlt } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useBranch } from '../../components/BranchProvider/BranchProvider.js';
import './Navbar.css';

const Navbar = ({
  colorSFR = "text-white",
  colorRest = "text-white",
  colorBox = "bg-black",
  colortextBox = "text-white",
  colorhover = "#464646",
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const { branch } = useBranch();

  const hoverStyle = {
    '--hover-color': colorhover
  };

  const menuItems = {
    about: {
      label: "ABOUT",
      items: [
        { path: "/about/vision", label: "VISION" },
        { path: "/about/team", label: "TEAM" },
        { path: "/about/contact", label: "CONTACT" },
        { path: "/about/what-we-do", label: "WHAT WE DO" }
      ]
    },
    volunteer: {
      label: "VOLUNTEER",
      items: [
        { path: "/volunteer/calendar", label: "CALENDAR" },
        { path: "/volunteer/branch-creation", label: "BRANCH CREATION" },
        { path: "/volunteer/branch-renewal", label: "BRANCH RENEWAL" }
      ]
    },
    gallery: {
      label: "GALLERY",
      items: [
        { path: "/gallery/events", label: "EVENT GALLERY" },
        { path: "/gallery/newsletters", label: "NEWSLETTERS GALLERY" }
      ]
    },
    resources: {
      label: "RESOURCES",
      items: [
        { path: "/resources/general", label: "GENERAL WEBSITES" },
        { path: "/resources/africa", label: "AFRICAN REFUGEE CRISIS" },
        { path: "/resources/middleeast", label: "MIDDLE EASTERN REFUGEE CRISIS" },
        { path: "/resources/policy", label: "REFUGEE MIGRATION POLICY" },
        { path: "/resources/latinamerica", label: "LATIN AMERICA REFUGEE CRISIS" }
      ]
    },
  };

  const standardMenuItems = [
    { path: "/", label: "HOME" },
    { path: "/donate", label: "DONATE" },
    { path: "/impact", label: "IMPACT" }
  ];

  const getDropdownHeight = (type) => {
    const itemCount = menuItems[type].items.length;
    const itemHeight = 2.1;
    const bottomPadding = 0.1;
    return `${(itemCount * itemHeight) + bottomPadding}rem`;
  };

  const getDropdownAlignment = (key) => {
    if (key === 'resources' || key === 'gallery') return 'right-0';
    return 'left-0';
  };

  const DropdownMenu = ({ items, type }) => (
    <ul className={`absolute ${getDropdownAlignment(type)} top-full ${colorBox} ${colortextBox} w-80 text-center z-50`}
        style={{ height: getDropdownHeight(type) }}>
      {items.map(item => (
        <Link key={item.path} to={item.path}>
          <li className="pt-2 pl-2 text-left text-sm whitespace-nowrap nav-hover">
            {item.label}
          </li>
        </Link>
      ))}
    </ul>
  );

  const MobileSubmenu = ({ items, onBack }) => (
    <div className={`fixed right-0 top-0 h-full w-80 ${colorBox} shadow-lg transform transition-all duration-300 ease-in-out z-50`}>
      <div className="flex justify-between items-center p-4 border-b border-gray-700">
        <button 
          onClick={onBack}
          className={`${colortextBox} text-lg flex items-center`}
        >
          <span className="mr-2">«</span> Back
        </button>
        <button 
          onClick={() => setIsNavOpen(false)}
          className={`${colortextBox} p-2`}
        >
          <AiOutlineClose size={20} />
        </button>
      </div>
      <div className="p-4">
        {items.map(item => (
          <Link
            key={item.path}
            to={item.path}
            className={`block ${colortextBox} py-2`}
            onClick={() => setIsNavOpen(false)}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );

  return (
    <nav className="relative z-50 w-full" style={hoverStyle}>
      <div className="w-full px-4 md:px-6 bg-transparent">
        <div className={`flex justify-between items-center h-20 md:h-28 w-full mx-auto ${colorRest}`}>
          <Link to="/" className={`flex-shrink-0 ${colorSFR}`}>
            <div className="text-xl md:text-2xl lg:text-3xl font-bold whitespace-nowrap">
              Students for Refugees
            </div>
            {branch && branch !== "National Team" && (
              <div className="text-base md:text-lg lg:text-xl">@{branch}</div>
            )}
          </Link>

          <ul className="hidden lg:flex lg:items-center lg:gap-2 xl:gap-3 lg:flex-nowrap">
            {standardMenuItems.map(item => (
              <li key={item.path}>
                <Link to={item.path} className="text-sm xl:text-base font-medium px-1.5 xl:px-2 nav-hover">
                  {item.label}
                </Link>
              </li>
            ))}
            {Object.entries(menuItems).map(([key, { label, items }]) => (
              <li key={key}
                  className="relative"
                  onMouseEnter={() => setActiveDropdown(key)}
                  onMouseLeave={() => setActiveDropdown(null)}>
                <span className="cursor-pointer text-sm xl:text-base font-medium px-1.5 xl:px-2 nav-hover">
                  {label}
                </span>
                {activeDropdown === key && (
                  <DropdownMenu 
                    items={items} 
                    type={key}
                  />
                )}
              </li>
            ))}
          </ul>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsNavOpen(!isNavOpen)} 
            className="lg:hidden"
          >
            <CgMenuRightAlt size={24} />
          </button>

          {/* Mobile Menu */}
          {isNavOpen && (
            <>
              {/* Backdrop */}
              <div 
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={() => setIsNavOpen(false)}
              />
              
              {/* Menu Panel */}
              <div className={`fixed right-0 top-0 h-full w-80 ${colorBox} shadow-lg transform transition-all duration-300 ease-in-out z-50`}>
                <div className="flex justify-end p-4 border-b border-gray-700">
                  <button 
                    onClick={() => setIsNavOpen(false)}
                    className={`${colortextBox} p-2`}
                  >
                    <AiOutlineClose size={20} />
                  </button>
                </div>
                <div className="p-4">
                  <Link
                    to="/"
                    className={`block ${colortextBox} text-lg py-2`}
                    onClick={() => setIsNavOpen(false)}
                  >
                    HOME
                  </Link>
                  {Object.entries(menuItems).map(([key, { label, items }]) => (
                    <div key={key} className="py-2">
                      <button
                        onClick={() => setActiveSubmenu(key)}
                        className={`${colortextBox} text-lg w-full text-left flex justify-between items-center`}
                      >
                        {label}
                        <span className="ml-2">»</span>
                      </button>
                    </div>
                  ))}
                  {standardMenuItems.slice(1).map(item => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`block ${colortextBox} text-lg py-2`}
                      onClick={() => setIsNavOpen(false)}
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
              </div>
              
              {/* Submenu Panel */}
              {activeSubmenu && (
                <MobileSubmenu
                  items={menuItems[activeSubmenu].items}
                  onBack={() => setActiveSubmenu(null)}
                />
              )}
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;