import React from "react";
import Home from "./pages/Home/Home";
import Contact from "./pages/About/Contact/Contact";
import Vision from "./pages/About/OurVision/OurVision";
import EventGallery from "./pages/Gallery/EventGallery/EventGallery";
import Donation from "./pages/Donate/Donate";
import IndividualEventGallery from "./components/IndividualEventGallery/IndividualEventGallery";
import ResourcesGen from "./pages/Resources/GeneralWebsites/GeneralWebsites";
import ResourcesAfr from "./pages/Resources/Africa/Africa";
import ResourcesLat from "./pages/Resources/Latin_America/Latin_America";
import ResourcesMid from "./pages/Resources/MiddleEast/MiddleEast";
import ResourcesPol from "./pages/Resources/MigrationPolicy/MigrationPolicy";
import CalendarEvents from "./pages/Volunteer/CalendarEvents/CalendarEvents";
import MeetTeam from "./pages/About/Team/Team";
import NewsletterGallery from "./pages/Gallery/NewsLetterGal/NewsLetterGal";
import IndividualNewsletter from "./components/IndividualNewletter/IndividualNewsletter";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { ErrorBoundary } from 'react-error-boundary';
import BranchCreation from "./pages/Volunteer/BranchCreation/BranchCreation";
import BranchRenwal from "./pages/Volunteer/BranchRenewal/BranchRenwal";
import Impact from "./pages/Impact/Impact";
import WhatWeDo from "./pages/About/WhatWeDo/WhatWeDo";

const ErrorFallback = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="text-center p-8">
      <h2 className="text-2xl font-bold text-red-600">Something went wrong</h2>
      <button
        onClick={() => window.location.reload()}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        Try again
      </button>
    </div>
  </div>
);

function App() {
  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about/vision" element={<Vision />} />
            <Route path="/about/team" element={<MeetTeam />} />
            <Route path="/about/contact" element={<Contact />} />
            <Route path="/about/what-we-do" element={<WhatWeDo />} />
            <Route exact path="/donate" element={<Donation />} />
            <Route exact path="/impact" element={<Impact />} />
            <Route path="/volunteer/calendar" element={<CalendarEvents />} />
            <Route path="/volunteer/branch-creation" element={<BranchCreation />} />
            <Route path="/volunteer/branch-renewal" element={<BranchRenwal />} />
            <Route path="/resources/general" element={<ResourcesGen />} />
            <Route path="/resources/africa" element={<ResourcesAfr />} />
            <Route path="/resources/latinamerica" element={<ResourcesLat />} />
            <Route path="/resources/middleeast" element={<ResourcesMid />} />
            <Route path="/resources/policy" element={<ResourcesPol />} />
            <Route exact path="/gallery/events" element={<EventGallery />} />
            <Route
              path="/gallery/events/:eventName"
              element={<IndividualEventGallery />}
            />
            <Route path="/gallery/newsletters" element={<NewsletterGallery />} />
            <Route
              path="/gallery/newsletters/:newsletterName"
              element={<IndividualNewsletter />}
            />
          </Routes>
        </Router>
    </ErrorBoundary>
  );
}

export default App;
