import React, { useState, useEffect } from "react"; // Changed to useLayoutEffect
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  Typography,
  styled,
  IconButton
} from "@mui/material";
import { X } from "lucide-react";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import bannerImage from "../../images/Banner_LandingPage.jpg";
import Navbar from "../../components/Navbar/Navbar";
import { ReactTyped } from "react-typed";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useBranch } from "../../components/BranchProvider/BranchProvider.js";

const BRANCH_COLORS = {
  "National Team": { main: "#53C2C8", hover: "#7dd3d7" },
  "Carnegie Vanguard": { main: "#0430EB", hover: "#4265ef" },
  Bellaire: { main: "#E41937", hover: "#eb4d65" },
  DeBakey: { main: "#0049AD", hover: "#1a6cd4" },
  "Texas A&M": { main: "#510104", hover: "#7a0206" },
  Challenge: { main: "#FF0000", hover: "#ff4d4d" },
  "University of Houston": { main: "#C8102E", hover: "#e13351" },
  Westside: { main: "#3ca195", hover: "#4ec3b5" },
  Homeschool: { main: "#9966cc", hover: "#b38fe0" },
  default: { main: "#53C2C8", hover: "#7dd3d7" }
};

const GlassButton = styled(Button)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  borderRadius: "50px",
  padding: "12px 24px",
  fontSize: "1.3rem",
  transition: "all 0.3s ease",
  height: '8vh',
  textTransform: "none",
  whiteSpace: "nowrap",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.2)",
    transform: "translateY(-2px)",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)"
  },
  "@media (max-width: 580px)": {
    padding: "8px 16px",
    fontSize: "1rem",
    height: '7vh',
  },
  "@media (max-width: 320px)": {
    padding: "6px 12px",
    fontSize: "0.75rem",
    height: '6vh',
  }
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "white",
    backdropFilter: "blur(10px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "12px",
    color: "black",
    width: "90%",
    maxWidth: "600px",
    position: "relative",
    overflowY: "visible",
    "@media (max-width: 320px)": {
      width: "80%",
      padding: "8px",
      margin: "8px"
    }
  }
}));

const CloseButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'black',
  zIndex: 1,
});

const MetricBox = styled(Box)({
  textAlign: "center",
  color: "white",
  backdropFilter: "blur(10px)",
  background: "rgba(255, 255, 255, 0.1)",
  borderRadius: "20px",
  padding: "15px",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "translateY(-5px)"
  },
  "@media (max-width: 580px)": {
    padding: "10px"
  },
  "@media (max-width: 320px)": {
    padding: "8px"
  }
});

function Home() {
  const { branch, setBranch } = useBranch();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    // First scroll to top
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    // Wait for scroll animation to complete before showing modal
    setTimeout(() => {
      setShowModal(true);
      document.body.style.overflow = 'hidden';
    }, 350); // Adjust timing based on your scroll animation duration
  };

  const lockScroll = () => {
    // Add styles to body
    const body = document.body;
    body.style.position = 'fixed';
    body.style.width = '100%';
  };

  const unlockScroll = () => {
    // Remove styles from body
    const body = document.body;
    body.style.position = '';
    body.style.top = '';
    body.style.width = '';
    body.style.overflow = ''; // Added line to reset overflow
  };

  useEffect(() => {
    if (showModal) {
      lockScroll();
    } else {
      unlockScroll();
    }
    
    // Cleanup function
    return () => {
      const body = document.body;
      body.style.position = '';
      body.style.top = '';
      body.style.width = '';
      body.style.overflow = ''; // Ensure overflow is reset on unmount
    };
  }, [showModal]);

  const handleClose = () => {
    setShowModal(false);
    document.body.style.overflow = 'unset';
  };

  useEffect(() => {
    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handleBranchSelection = (event) => {
    const selectedBranch = event.target.value;
    setBranch(selectedBranch);
    const branchColors = BRANCH_COLORS[selectedBranch] || BRANCH_COLORS["default"];
    document.documentElement.style.setProperty("--main-color", branchColors.main);
    document.documentElement.style.setProperty("--main-text-color", branchColors.main);
    document.documentElement.style.setProperty("--main-hover-color", branchColors.hover);
    handleClose();
  };

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 }
  };

  return (
    <motion.div
      initial="initial"
      animate="animate"
      exit="exit"
      variants={pageVariants}
      transition={{ duration: 0.6 }}
    >
      <Box
        sx={{
          minHeight: "100vh",
          background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: { xs: "50% 35%", sm: "center" },
          backgroundAttachment: { xs: "scroll", sm: "fixed" },
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Navbar hideDefault={true} />

        <Box sx={{ 
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          minHeight: { xs: '80vh', sm: '90vh' },
          px: { xs: 3, sm: 6, md: 8, lg: 13 },
          width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' },
          pt: { 
            xs: '1vh',
            sm: '8vh',
            md: '10vh',
          },
        }}>
          <Box
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", sm: "400px", md: "450px" }
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontSize: {
                    xs: "1.75rem",
                    sm: "2rem",
                    md: "2.5rem",
                    lg: "3.5rem"
                  },
                  fontWeight: 900,
                  mb: { xs: 1, sm: 2 },
                  lineHeight: 1.2
                }}
              >
                The future belongs to us.
              </Typography>
            </motion.div>

            <Box sx={{ 
              mb: { xs: 1.5, sm: 3 }, 
              fontWeight: 900, 
              color: "white",
              fontSize: {
                xs: "1.25rem",
                sm: "1.75rem",
                md: "2rem",
              },
            }}>
              <ReactTyped
                strings={["Learn More", "Volunteer", "Donate"]}
                typeSpeed={80}
                backSpeed={80}
                backDelay={2000}
                loop
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: { xs: 1, sm: 2 },
                mb: { xs: 1.5, sm: 2 },
                width: "100%"
              }}
            >
              <Link to="/about/vision" style={{ width: "50%" }}>
                <GlassButton
                  fullWidth
                  sx={{
                    color: "var(--main-color)",
                    background: "white",
                    "&:hover": { background: "#EDEADE" }
                  }}
                >
                  Learn More
                </GlassButton>
              </Link>
              <Link to="/donate" style={{ width: "50%" }}>
                <GlassButton
                  fullWidth
                  sx={{
                    background: "var(--main-color)",
                    color: "white",
                    "&:hover": { background: "var(--main-hover-color)" }
                  }}
                >
                  Donate
                </GlassButton>
              </Link>
            </Box>

            <GlassButton
              onClick={handleOpenModal}
              fullWidth
              sx={{
                background: "var(--main-color)",
                color: "white",
                mb: { xs: 1.5, sm: 2 },
                "&:hover": { background: "var(--main-hover-color)" }
              }}
            >
              Select Branch
            </GlassButton>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "repeat(3, 1fr)",
                  sm: "repeat(3, 1fr)"
                },
                gap: { xs: 1, sm: 2 },
                mt: { xs: 1, sm: 2 }
              }}
            >
              <MetricBox
                sx={{
                  background: "var(--main-color)",
                  color: "white",
                  "&:hover": { background: "var(--main-hover-color)" }
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "1.25rem", sm: "2rem", md: "2.5rem" }
                  }}
                >
                  <CountUp end={1000} duration={2.5} />+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.875rem" }
                  }}
                >
                  Hours of Tutoring
                </Typography>
              </MetricBox>
              <MetricBox
                sx={{
                  background: "var(--main-color)",
                  color: "white",
                  "&:hover": { background: "var(--main-hover-color)" }
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "1.25rem", sm: "2rem", md: "2.5rem" }
                  }}
                >
                  <CountUp end={4000} duration={2.5} />+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.875rem" }
                  }}
                >
                  Refugees Helped
                </Typography>
              </MetricBox>
              <MetricBox
                sx={{
                  background: "var(--main-color)",
                  color: "white",
                  "&:hover": { background: "var(--main-hover-color)" }
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: "1.25rem", sm: "2rem", md: "2.5rem" }
                  }}
                >
                  <CountUp end={100} duration={2.5} />+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.875rem" }
                  }}
                >
                  Active Members
                </Typography>
              </MetricBox>
            </Box>
          </Box>
        </Box>

        <StyledDialog
          open={showModal}
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
          sx={{
            '.MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          }}
        >
          <CloseButton onClick={handleClose} aria-label="close">
            <X size={24} />
          </CloseButton>
          <DialogTitle>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
                fontSize: { xs: "1.125rem", sm: "1.5rem" },
                padding: { xs: "8px 0", sm: "16px 0" },
                pr: 4
              }}
            >
              Select Your Branch
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ py: { xs: 1, sm: 2 } }}>
            <Select
              value={branch || "default"}
              onChange={handleBranchSelection}
              fullWidth
              MenuProps={{
                sx: {
                  maxHeight: '300px',
                  '& .MuiPaper-root': {
                    maxHeight: '300px'
                  }
                }
              }}
              sx={{
                fontSize: { xs: "0.875rem", sm: "1rem" },
                height: { xs: "40px", sm: "56px" },
                background: "white",
                color: "black"
              }}
            >
              <MenuItem value="default">Select</MenuItem>
              {Object.keys(BRANCH_COLORS)
                .filter((branchName) => branchName !== "default")
                .map((branchName) => (
                  <MenuItem
                    key={branchName}
                    value={branchName}
                    sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}
                  >
                    {branchName}
                  </MenuItem>
                ))}
            </Select>
          </DialogContent>
        </StyledDialog>
      </Box>
      <Footer />
    </motion.div>
  );
}

export default Home;
