import React from 'react';
import DOMPurify from 'dompurify';

const UpComingEvent = ({ events }) => {
  const now = new Date();
  const upcomingEvent = events.find(event => 
    new Date(event.start.dateTime || event.start.date) > now
  );

  if (!upcomingEvent) {
    return (
      <div className='flex text-black justify-center text-center text-lg lg:text-2xl mb-24 items-center mx-auto min-w-[30vh] w-[33%]'>
        Future events will all be posted on the calendar below. At the moment,
        there are no volunteer events scheduled for this month, but check back later
        for any future updates.
      </div>
    );
  }

  const eventDate = new Date(upcomingEvent.start.dateTime || upcomingEvent.start.date).toLocaleString();

  const addBlankTarget = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const links = doc.querySelectorAll('a');

    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  };

  const eventDescription = upcomingEvent.description 
    ? addBlankTarget(upcomingEvent.description) 
    : "No additional details available, at the moment.";

  const cleanDescription = DOMPurify.sanitize(eventDescription);

  return (
    <div className='flex flex-col justify-center container mx-auto my-8 p-6 md:p-10 lg:p-12 max-w-md sm:max-w-xl md:max-w-3xl rounded-lg shadow-lg bg-white'>
      <h1 className='text-xl sm:text-2xl font-bold mb-8 text-left'>Upcoming Event:</h1>
      <h4 className='text-3xl md:text-4xl main_text font-bold'>{upcomingEvent.summary}</h4>
      <p className='text-xl md:text-2xl mt-4'>Date: {eventDate}</p>
      {upcomingEvent.location && (
        <p className='text-xl md:text-2xl mt-2'>Location: {upcomingEvent.location}</p>
      )}
      <div 
        className='text-lg md:text-xl mt-4'
        dangerouslySetInnerHTML={{ __html: cleanDescription }}
      />
    </div>
  );
};

export default UpComingEvent;