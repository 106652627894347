import React from 'react';
import { Link } from "react-router-dom";
import { Mail } from 'lucide-react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';

const GlassButton = ({ children, className = "", onClick = () => {}, variant = "default" }) => {
  const baseStyles = "backdrop-blur-md transition-all duration-300 rounded-3xl font-medium text-lg flex items-center justify-center w-full sm:w-auto mx-auto px-8 py-4";
  
  const variants = {
    default: "bg-opacity-10 border border-white/20 hover:bg-opacity-20 text-white",
    solid: "bg-white hover:bg-opacity-90 text-black"
  };

  return (
    <button
      onClick={onClick}
      className={`
        ${baseStyles}
        ${variants[variant]}
        ${className}
        transform hover:-translate-y-1 hover:shadow-lg
      `}
      style={{
        backgroundColor: variant === "default" ? 'var(--main-color)' : undefined,
        '--tw-bg-opacity': variant === "default" ? '0.1' : '1',
      }}
    >
      {children}
    </button>
  );
};

const Footer = () => {
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  return (
    <footer className="relative overflow-hidden">
      <div className="main_bg text-white min-h-[80vh] pb-16 pt-24 px-4">
        {/* Decorative background elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div 
            className="absolute -top-40 -left-40 w-80 h-80 rounded-full blur-3xl opacity-20"
            style={{ backgroundColor: 'var(--main-color)' }}
          />
          <div 
            className="absolute -bottom-40 -right-40 w-80 h-80 rounded-full blur-3xl opacity-20"
            style={{ backgroundColor: 'var(--main-hover-color)' }}
          />
        </div>

        {/* Content container */}
        <div className="relative max-w-4xl mx-auto">
          {/* Quote Section */}
          <div className="text-center mb-16">
            <blockquote className="mb-4">
              <p className="text-2xl sm:text-4xl font-bold leading-relaxed mb-6">
                "If you want others to be happy, practice compassion. If you want to be happy, practice compassion."
              </p>
              <footer className="text-lg sm:text-xl text-white/80">
                — H.H Dalai Lama
              </footer>
            </blockquote>
          </div>

          {/* Organization Info */}
          <div className="text-center space-y-2 mb-16">
            <p className="text-lg font-medium">Students for Refugees</p>
            <p className="text-sm text-white/80">Est. 2019</p>
          </div>

          {/* Buttons Container */}
          <div className="space-y-4 max-w-md mx-auto">
            <Link to="/about/vision" onClick={handleClick} className="block">
              <GlassButton
                variant="default"
                className="group"
              >
                <span className="group-hover:scale-105 transition-transform duration-300">
                  Learn More
                </span>
              </GlassButton>
            </Link>

            <a 
              href="https://linktr.ee/studentsforrefugees" 
              target="_blank" 
              rel="noreferrer"
              className="block"
            >
              <GlassButton
                variant="solid"
                className="group font-bold"
              >
                <span className="group-hover:scale-105 transition-transform duration-300">
                  VIEW OUR LINKED TREE
                </span>
              </GlassButton>
            </a>
          </div>

          {/* Social Links */}
          <div className="mt-16 flex justify-center space-x-8">
            <a 
              href="mailto:hello@studentsforrefugees.org"
              className="text-white hover:text-white/80 transition-colors duration-300 hover:-translate-y-1 transform"
              aria-label="Email"
            >
              <Mail className="w-6 h-6" />
            </a>
            <a 
              href="https://www.instagram.com/sfrnational/"
              target="_blank"
              rel="noreferrer"
              className="text-white hover:text-white/80 transition-colors duration-300 hover:-translate-y-1 transform"
              aria-label="Instagram"
            >
              <FaInstagram className="w-6 h-6" />
            </a>
            <a 
              href="https://www.linkedin.com/company/studentsforrefugees"
              target="_blank"
              rel="noreferrer"
              className="text-white hover:text-white/80 transition-colors duration-300 hover:-translate-y-1 transform"
              aria-label="LinkedIn"
            >
              <FaLinkedin className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;