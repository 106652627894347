import React, { useState } from "react";
import { motion } from "framer-motion";
import { ExternalLink, Globe, BookOpen, ArrowRight } from "lucide-react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const ResourceCard = ({ organization, links, onExpand, isExpanded }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onClick={onExpand}
      className="w-full bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer"
    >
      <div className="p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Globe className="w-6 h-6 main_text" />
            <h2 className="text-xl md:text-2xl font-bold main_text">
              {organization}
            </h2>
          </div>
          <motion.div
            animate={{ rotate: isExpanded ? 90 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ArrowRight className="w-5 h-5 main_text" />
          </motion.div>
        </div>

        <motion.div
          initial={false}
          animate={{
            height: isExpanded ? "auto" : 0,
            opacity: isExpanded ? 1 : 0,
            marginTop: isExpanded ? 16 : 0
          }}
          transition={{ duration: 0.3 }}
          className="overflow-hidden"
        >
          <div className="space-y-3">
            {links.map((link, index) => (
              <motion.div
                key={index}
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center gap-2 group"
                onClick={(e) => e.stopPropagation()} // Prevent card collapse when clicking links
              >
                <ExternalLink className="w-4 h-4 text-gray-400 group-hover:main_text transition-colors duration-200" />
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:main_text text-sm md:text-base transition-colors duration-200 break-all"
                >
                  {link}
                </a>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

const Quote = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8 }}
    className="relative w-full max-w-4xl mx-auto px-4 py-12"
  >
    <div className="absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden">
      <div className="absolute -top-20 -left-20 w-40 h-40 lg:w-56 lg:h-56 rounded-full btn-main opacity-5" />
      <div className="absolute bottom-1/4 -right-20 w-40 h-40 lg:w-56 lg:h-56 rounded-full btn-main opacity-5" />
    </div>

    <div className="relative bg-white rounded-2xl shadow-lg p-8 md:p-12">
      <BookOpen className="w-8 h-8 main_text mb-6" />
      <blockquote className="text-2xl md:text-3xl font-bold main_text mb-4">
        "Education is the most powerful weapon which you can use to change the
        world."
      </blockquote>
      <cite className="text-gray-600 text-lg">— Nelson Mandela</cite>
    </div>
  </motion.div>
);

const Africa = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const resources = [
    {
      organization: "Aljazeera",
      links: [
        "https://www.aljazeera.com/news/2019/2/9/what-you-need-to-know-about-africas-refugees"
      ]
    },
    {
      organization: "UNHCR",
      links: [
        "https://www.unhcr.org/us/about-unhcr/where-we-work/africa#:~:text=Sub%2DSaharan%20Africa%20hosts%20more,)%2C%20Nigeria%20and%20South%20Sudan"
      ]
    },
    {
      organization: "UN",
      links: [
        "https://www.un.org/africarenewal/magazine/december-2016-march-2017/africa-most-affected-refugee-crisis",
        "https://www.un.org/africarenewal/news/forced-displacement-we-can-learn-africa",
        "https://www.unrefugees.org/news/south-sudan-refugee-crisis-explained/"
      ]
    },
    {
      organization: "Migration Policy",
      links: [
        "https://www.migrationpolicy.org/article/forgotten-crisis-displacement-central-african-republic"
      ]
    },
    {
      organization: "World Vision",
      links: [
        "https://www.worldvision.org/refugees-news-stories/south-sudan-conflict-facts"
      ]
    },
    {
      organization: "BBC",
      links: ["https://www.bbc.com/news/world-africa-14095300"]
    },
    {
      organization: "Vox",
      links: [
        "https://www.vox.com/2020/9/11/21431157/trump-refugee-admissions-resettlement-cap-2021"
      ]
    },
    {
      organization: "Human Rights Watch",
      links: [
        "https://www.hrw.org/news/2020/12/21/sudan-security-forces-kill-protesters-eastern-sudan",
        "https://www.hrw.org/world-report/2020/country-chapters/sudan"
      ]
    },
    {
      organization: "CARE",
      links: [
        "https://care.org/our-work/disaster-response/emergencies/sudan-humanitarian-crisis/#:~:text=Civilians%20have%20suffered%20greatly%20from,200%2D250%20percent%20during%202019."
      ]
    }
  ];

  return (
    <div>
      <div className="min-h-screen bg-gray-50">
        <Navbar
          colorSFR="main_text"
          colorRest="text-[#9999A3]"
          colorBox="bg-black"
          colortextBox="text-white"
          colorhover="#464646"
        />
        <Quote />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="max-w-4xl mx-auto px-4 py-12"
        >
          <div className="space-y-4">
            {resources.map((resource, index) => (
              <ResourceCard
                key={index}
                {...resource}
                isExpanded={expandedCard === index}
                onExpand={() =>
                  setExpandedCard(expandedCard === index ? null : index)
                }
              />
            ))}
          </div>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default Africa;
