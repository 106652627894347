import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import UpComingEvent from "../../../components/UpcomingEvent/UpcomingEvent";
import MyCalendar from "../../../components/MyCalendar/MyCalendar";

const CalendarEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/calendar-events`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []); // Removed the visibility change event listener

  return (
    <div className="min-h-screen">
      <Navbar 
        colorSFR="main_text" 
        colorRest="text-[#9999A3]" 
        colorBox="bg-black" 
        colortextBox="text-white" 
        colorhover="#464646" 
      />
      
      <div className="flex-grow flex flex-col items-center justify-center px-4 py-8 w-full mb-24">
        <h1 className="text-5xl lg:text-6xl font-bold main_text mt-24 mb-12 text-center">
          Volunteer Events
        </h1>

        {loading ? (
          <div className="flex flex-col items-center justify-center h-[60vh]">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            <p className="mt-4 text-xl text-gray-600">Loading events...</p>
          </div>
        ) : (
          <>
            <div className="w-full max-w-4xl mx-auto">
              <UpComingEvent events={events} />
            </div>

            <div className="w-full max-w-7xl mx-auto my-8">
              <MyCalendar events={events} />
            </div>
          </>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default CalendarEvents;